import React from "react"
import { graphql } from "gatsby"
import { PagesTopQuery } from "@/types"
import Layout from "@components/layouts"
import Meta from "@components/layouts/meta"
import Page from "@components/pages/2020/top"
import { useToggleLanguage } from "@components/hooks"
// ______________________________________________________
//
type Props = {
  data: PagesTopQuery
}
// ______________________________________________________
//
const Component: React.FC<Props> = props => (
  <Layout env={props.data.site?.siteMetadata?.env ?? ""}>
    <Meta />
    <Page {...props.data} {...useToggleLanguage()} />
  </Layout>
)
// ______________________________________________________
//
export const pageQuery = graphql`
  query PagesTop {
    site {
      siteMetadata {
        env
        title
        menu
        footerLinkListsEN {
          navListItems {
            target
            label
            href
          }
          title
        }
        footerLinkListsJA {
          navListItems {
            target
            label
            href
          }
          title
        }
      }
    }
    allContentfulParagraph {
      edges {
        node {
          text {
            text
          }
          node_locale
          title
        }
      }
    }
    allContentfulSpeaker {
      edges {
        node {
          twitterAccount
          githubAccount
          node_locale
          name
          order
          slug
          avatar {
            file {
              url
            }
          }
          session {
            slug
            publishDetailPage
          }
        }
      }
    }
    allContentfulSession {
      edges {
        node {
          speakers {
            name
            avatar {
              file {
                url
              }
            }
          }
          node_locale
          publishDetailPage
          name
          endsAt
          startsAt
          slug
        }
      }
    }
    allContentfulProgram {
      edges {
        node {
          name
          iconName
          node_locale
          startsAt
          endsAt
        }
      }
    }
    allContentfulSponsor {
      edges {
        node {
          name
          node_locale
          logo {
            file {
              url
            }
          }
          plan
          url
          createdAt
        }
      }
    }
    allContentfulCoreStaff {
      edges {
        node {
          name
          order
          node_locale
          avatar {
            file {
              url
            }
          }
        }
      }
    }
    # allContentfulVolunteer {
    #   edges {
    #     node {
    #       name
    #       node_locale
    #       avatar {
    #         file {
    #           url
    #         }
    #       }
    #     }
    #   }
    # }
  }
`
// ______________________________________________________
//
export default Component
