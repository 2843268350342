import React from "react"
import styled from "styled-components"
import media from "styled-media-query"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"
// ______________________________________________________
//
type Props = {
  className?: string
}
// ______________________________________________________
//
const Component: React.FC<Props> = props => (
  <div className={props.className}>
    <StaticQuery
      query={query}
      render={data => <Img fixed={data.file.childImageSharp.fixed} />}
    />
  </div>
)
// ______________________________________________________
//
const pcStyle = `
`
const spStyle = `
.gatsby-image-wrapper {
  width: 360px !important;
  height: 205px !important;
}
`
const StyledComponent = styled(Component)`
  ${media.lessThan("medium")`${spStyle}`}
  ${media.greaterThan("medium")`${pcStyle}`}
`
// ______________________________________________________
//
export default StyledComponent
// ______________________________________________________
//
const query = graphql`
  query {
    file(relativePath: { eq: "photos.png" }) {
      childImageSharp {
        fixed(width: 541, quality: 80) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
