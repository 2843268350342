import React from "react"
import styled from "styled-components"
import media from "styled-media-query"
import Title from "@/src/components/common/title/h2"
import Button from "@components/common/button"
import Image from "./image"
import { PagesTopQuery } from "@/types"
// ______________________________________________________
//
export type Paragraph = PagesTopQuery["allContentfulParagraph"]["edges"][0]
type Props = {
  className?: string
  paragraph?: Paragraph
}
// ______________________________________________________
//
const Component: React.FC<Props> = props => (
  <section className={props.className} id="venue">
    <div className="container">
      <Title className="title" figurePosition="right">
        VENUE
      </Title>
      <p className="venue">NAVITIME JAPAN</p>
      <p className="location">{props.paragraph?.node?.text?.text || ""}</p>
      <Image className="image" />
      <Button
        className="btn"
        label="Google Map"
        href="https://goo.gl/maps/z9Kmy82UopBxcP318"
        target="_blank"
        height={70}
        backgroundColor="light"
      />
    </div>
  </section>
)
// ______________________________________________________
//
const pcStyle = `
width: 100%;
overflow: hidden;
> .container {
  width: 970px;
  margin: 0 auto;
  padding: 40px 40px 120px;
  position: relative;
  > .venue {
    width: 530px;
    padding: 20px 0;
    font-size: 3.6rem;
  }
  > .location {
    width: 530px;
    margin-bottom: 40px;
  }
  > .image {
    position: absolute;
    top: 40px;
    right: 40px;
  }
  > .btn {
    width: 320px;
  }
}
`
const spStyle = `
> .container {
  padding: 20px 20px 60px 20px;
  > .venue {
    padding: 20px 0;
    font-size: 2.4rem;
  }
}
`
const StyledComponent = styled(Component)`
  > .container {
    > .venue {
      font-family: "Roboto", sans-serif;
    }
  }
  ${media.lessThan("medium")`
    background-color: ${({ theme }) => theme.colors.grape};
  ${spStyle}`}
  ${media.greaterThan("medium")`${pcStyle}`}
`
// ______________________________________________________
//
export default StyledComponent
