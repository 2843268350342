import React from "react"
import styled from "styled-components"
import media from "styled-media-query"
// ______________________________________________________
//
type Props = {
  className?: string
  figurePosition?: "right" | "left" | "bottom"
}
// ______________________________________________________
//
const Component: React.FC<Props> = props => (
  <h2 className={props.className}>{props.children}</h2>
)
// ______________________________________________________
//
export const titleFigureRight = `
display: flex;
justify-content: flex-start;
align-items: center;
&::after {
  content: "";
  display: block;
  width: 75px;
  height: 3px;
  margin-left: 30px;
  background-color: #fff;
}
`
export const titleFigureLeft = `
display: flex;
justify-content: flex-end;
align-items: center;
&::before {
  content: "";
  display: block;
  width: 75px;
  height: 3px;
  margin-right: 30px;
  background-color: #fff;
}
`
export const titleFigureBottom = `
padding: 15px 0;
line-height: 2;
text-align: center;
&::after {
  content: "";
  display: block;
  width: 75px;
  height: 3px;
  margin: 0 auto;
  background-color: #fff;
}
`
const StyledComponent = styled(Component)`
  ${media.lessThan("medium")`
    font-size: 3.6rem;
  `}
  ${media.greaterThan("medium")`
    font-size: 4.8rem;
  `}
  ${props => {
    switch (props.figurePosition) {
      case "right":
        return titleFigureRight
      case "left":
        return titleFigureLeft
      case "bottom":
        return titleFigureBottom
    }
  }}
`
// ______________________________________________________
//
export default StyledComponent
