import React from "react"
import styled from "styled-components"
import media from "styled-media-query"
// ______________________________________________________
//
type Props = {
  className?: string
}
// ______________________________________________________
//
const Component: React.FC<Props> = props => (
  <nav className={props.className}>
    <ul>
      <li>
        <a href="#about">ABOUT</a>
      </li>
      <li>
        <a href="#speakers">SPEAKERS</a>
      </li>
      <li>
        <a href="#time-table">TIME TABLE</a>
      </li>
      <li>
        <a href="#venue">VENUE</a>
      </li>
      <li>
        <a href="#spon">SPONSORS</a>
      </li>
    </ul>
  </nav>
)
// ______________________________________________________
//
const pcStyle = `
ul {
  display: flex;
  justify-content: center;
  position: relative;
}
a {
  display: block;
  padding: 18px 36px;
  text-decoration: none;
  font-family: 'Roboto', sans-serif;
}
`
const spStyle = `
ul {
  height: 12px;
}
li {
  display: none;
}
`
const StyledComponent = styled(Component)`
  position: relative;
  background-color: ${({ theme }) => theme.colors.primary};
  &::before {
    background-color: ${({ theme }) => theme.colors.primary};
  }
  li {
    position: relative;
    &::after {
      position: absolute;
      content: "";
      width: 1px;
      top: calc(50% - 5%);
      display: block;
      height: 10%;
      background: ${({ theme }) => theme.colors.light};
    }
    &:first-child {
      &::after {
        content: none;
      }
    }
  }

  a {
    color: ${({ theme }) => theme.colors.light};
    transition: color ease-out 0.1s;
    &:hover {
      color: ${({ theme }) => theme.colors.sky};
    }
  }
  ${media.lessThan("medium")`${spStyle}`}
  ${media.greaterThan("medium")`${pcStyle}`}
`
// ______________________________________________________
//
export default StyledComponent
