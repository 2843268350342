import React from "react"
import styled from "styled-components"
import media from "styled-media-query"
import { UseToggleLanguage } from "@components/hooks"
import HeadTSConfJP from "@components/common/headTSConfJP"
import SelectLanguages from "@components/common/selectLanguages"
import Main from "./main"
import Town from "./town"
import OpticalFlare from "./opticalFlare"
import Fuji from "./fuji"
import Clouds from "./clouds"
// ______________________________________________________
//
type Props = {
  className?: string
} & UseToggleLanguage
// ______________________________________________________
//
const Component: React.FC<Props> = props => (
  <div className={props.className}>
    <Fuji className="fuji" />
    <Town className="town" />
    <OpticalFlare className="opticalFlare" />
    <Clouds className="clouds" />

    <Main className="main" />
    <HeadTSConfJP className="tsconfjp" />
    <SelectLanguages
      className="selectLanguages"
      lang={props.lang}
      toggleToEN={props.toggleToEN}
      toggleToJA={props.toggleToJA}
    />
  </div>
)
// ______________________________________________________
//
const pcStyle = `
height: 90vh;
min-height: 600px;
> .fuji {
  right: 0;
  bottom: 0;
}
`
const spStyle = `
height: 70vh;
min-height: 560px;
> .opticalFlare {
  top: 40px;
  left: -60px;
  transform: scale(.6);
  transform-origin: left top;
}
> .fuji {
  width: 486px;
  right: -130px;
  bottom: 60px;
}
> .selectLanguages {
  display: none;
}
> .scroller {
  bottom: 30px;
}

`
const StyledComponent = styled(Component)`
  position: relative;
  overflow: hidden;
  background: linear-gradient(
    180deg,
    rgba(31, 28, 180, 0) 0%,
    rgba(31, 28, 180, 0.35) 100%
  );
  > .fuji {
    position: absolute;
  }
  > .town {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
  }
  > .opticalFlare {
    position: absolute;
    top: 60px;
    left: 12%;
  }
  > .clouds {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
  > .tsconfjp {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  > .main {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
  }
  > .selectLanguages {
    position: absolute;
    top: 0;
    right: 20px;
  }
  ${media.lessThan("medium")`${spStyle}`}
  ${media.greaterThan("medium")`${pcStyle}`}
`
// ______________________________________________________
//
export default StyledComponent
