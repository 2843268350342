import React from "react"
import styled from "styled-components"
import media from "styled-media-query"
import Title from "@/src/components/common/title/h2"
import List from "./list"
import { Session } from "./list/sessionItem"
import { Program } from "./list/programItem"
// ______________________________________________________
//
type Props = {
  className?: string
  sessions: Session[]
  programs: Program[]
}
// ______________________________________________________
//
const Component: React.FC<Props> = props => (
  <section className={props.className} id="time-table">
    <div className="container">
      <Title className="title" figurePosition="bottom">
        TIME TABLE
      </Title>
      <List sessions={props.sessions} programs={props.programs} />
    </div>
  </section>
)
// ______________________________________________________
//
const pcStyle = `
margin: 80px 0;
position: relative;
&::before {
  content: "";
  display: block;
  width: 60%;
  height: 100%;
  position: absolute;
  right: 0;
}
> .container {
  width: 970px;
  margin: 0 auto;
  padding: 0 40px;
  position: relative;
}
`
const spStyle = `
padding: 20px 0;
> .container {
  > .title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 20px;
    padding-top: 0;
    &::after {
      content: "";
      display: block;
      width: 75px;
      height: 3px;
      margin-left: 30px;
      background-color: #fff;
    }
  }
}
`
const StyledComponent = styled(Component)`
  ${media.lessThan("medium")`${spStyle}`}
  ${media.greaterThan("medium")`${pcStyle}`}
`
// ______________________________________________________
//
export default StyledComponent
