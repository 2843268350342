import React from "react"
import styled from "styled-components"
import media from "styled-media-query"
import SpeakerItem, { Speaker } from "./item"
import SpeakerItemNoLink from "./item/noLink"
// ______________________________________________________
//
export type Props = {
  className?: string
  speakers: Speaker[]
}
// ______________________________________________________
//
const Component: React.FC<Props> = props => (
  <ul className={props.className}>
    {props.speakers.map((speaker, index) => {
      if (speaker.node.name === "Anonymous") return null
      if (speaker.node.session && speaker.node.session[0]?.publishDetailPage) {
        return (
          <li key={index}>
            <SpeakerItem {...speaker} />
          </li>
        )
      }
      return (
        <li key={index}>
          <SpeakerItemNoLink {...speaker} />
        </li>
      )
    })}
  </ul>
)
// ______________________________________________________
//
const pcStyle = `
> li {
  width: 220px;
  margin: 0 30px 50px 0;
  &:nth-child(4n) {
    margin-right: 0;
  }
}
`
const spStyle = `
> li {
  width: 47%;
  margin-bottom: 6%;
  &:nth-child(2n) {
    margin-left: 6%;
  }
}
`
const StyledComponent = styled(Component)`
  display: flex;
  flex-wrap: wrap;
  ${media.lessThan("medium")`${spStyle}`}
  ${media.greaterThan("medium")`${pcStyle}`}
`
// ______________________________________________________
//
export default StyledComponent
