import React from "react"
import sortBy from "lodash.sortby"
import { PagesTopQuery } from "@/types"
import Hero from "./hero"
import Nav from "./nav"
import About from "./about"
import Speakers from "./speakers"
import TimeTable from "./time-table"
import Venue from "./venue"
import Sponsors from "./sponsors"
import Links from "./links"
import Team from "./team"
import Footer from "@components/common/footer"
import DrawerMenu from "@components/common/drawerMenu"
import { sortByOrder, sortByCreatedAt, filterByLang } from "@components/utils"
import { UseToggleLanguage } from "@components/hooks"
// ______________________________________________________
//
type Props = PagesTopQuery &
  UseToggleLanguage & {
    className?: string
  }
// ______________________________________________________
//
const Component: React.FC<Props> = props => (
  <div className={props.className}>
    <Hero
      lang={props.lang}
      toggleToEN={props.toggleToEN}
      toggleToJA={props.toggleToJA}
    />
    <Nav />
    <About
      paragraph={
        props.allContentfulParagraph.edges
          .filter(filterByLang(props.lang))
          .filter(edge => edge.node.title === "About paragraph")[0]
      }
    />
    <Speakers
      speakers={sortBy(
        props.allContentfulSpeaker.edges.filter(filterByLang(props.lang)),
        ["node.order", "node.name"]
      )}
    />
    <TimeTable
      sessions={props.allContentfulSession.edges.filter(
        filterByLang(props.lang)
      )}
      programs={props.allContentfulProgram.edges.filter(
        filterByLang(props.lang)
      )}
    />
    <Venue
      paragraph={
        props.allContentfulParagraph.edges
          .filter(filterByLang(props.lang))
          .filter(edge => edge.node.title === "Venue paragraph")[0]
      }
    />
    <Sponsors
      lists={[
        {
          title: "SPECIAL",
          plan: "special",
          sponsors: props.allContentfulSponsor.edges
            .filter(filterByLang(props.lang))
            .filter(edge => edge.node.plan === "special"),
        },
        {
          title: "PLATINUM",
          plan: "platinum",
          sponsors: props.allContentfulSponsor.edges
            .filter(filterByLang(props.lang))
            .filter(edge => edge.node.plan === "platinum")
            .sort(sortByCreatedAt),
        },
        {
          title: "GOLD",
          plan: "gold",
          sponsors: props.allContentfulSponsor.edges
            .filter(filterByLang(props.lang))
            .filter(edge => edge.node.plan === "gold")
            .sort(sortByCreatedAt),
        },
        {
          title: "SILVER",
          plan: "silver",
          sponsors: props.allContentfulSponsor.edges
            .filter(filterByLang(props.lang))
            .filter(edge => edge.node.plan === "silver")
            .sort(sortByCreatedAt),
        },
      ]}
    />
    <Links />
    <Team
      coreStaff={{
        title: "CORE STAFF",
        members: sortBy(
          props.allContentfulCoreStaff.edges.filter(filterByLang(props.lang)),
          ["node.order", "node.name"]
        ),
      }}
      volunteer={{
        title: "VOLUNTEER",
        members: [] /* props.allContentfulVolunteer.edges.filter(
          filterByLang(props.lang)
        )*/,
      }}
    />
    <Footer
      lists={
        props.lang === "ja"
          ? (props.site?.siteMetadata?.footerLinkListsJA as any)
          : (props.site?.siteMetadata?.footerLinkListsEN as any)
      }
    />
    <DrawerMenu
      lang={props.lang}
      toggleToEN={props.toggleToEN}
      toggleToJA={props.toggleToJA}
    />
  </div>
)
// ______________________________________________________
//
export default Component
