import React from "react"
import styled from "styled-components"
import media from "styled-media-query"
import Head from "./head"
import Body from "./body"
import { PagesTopQuery } from "@/types"
// ______________________________________________________
//
export type Paragraph = PagesTopQuery["allContentfulParagraph"]["edges"][0]
type Props = {
  className?: string
  paragraph: Paragraph
}
// ______________________________________________________
//
const Component: React.FC<Props> = props => (
  <section className={props.className} id="about">
    <div className="container">
      <Head />
      <Body paragraph={props.paragraph} />
    </div>
  </section>
)
// ______________________________________________________
//
const pcStyle = `
padding: 40px 0;
> .container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 970px;
  margin: 0 auto;
  padding: 40px;
}
`
const spStyle = `
padding-top: 150px;
> .container {
  padding: 20px 20px 60px 20px;
}
`
const StyledComponent = styled(Component)`
  ${media.lessThan("medium")`${spStyle}`}
  ${media.greaterThan("medium")`${pcStyle}`}
`
// ______________________________________________________
//
export default StyledComponent
