import React from "react"
import styled from "styled-components"
import media from "styled-media-query"
import Button from "@components/common/button"
// ______________________________________________________
//
type Props = {
  className?: string
}
// ______________________________________________________
//
const Component: React.FC<Props> = props => (
  <div className={props.className}>
    <Button
      className="btnBuyTicket"
      label="BUY TICKET"
      href="https://ti.to/tsconfjp/2020"
      target="_blank"
      height={70}
      backgroundColor="secondary"
    />
    <Button
      className="btnSponsorWanted"
      label="SPONSOR WANTED"
      href="/2020/entries/sponsor/"
      height={70}
      backgroundColor="accent"
    />
  </div>
)
// ______________________________________________________
//
const pcStyle = `
display: flex;
justify-content: center;
align-items: center;
padding: 40px 0;
> * {
  width: 320px;
  margin: 0 16px;
}
`
const spStyle = `
padding: 20px;
> * {
  margin-bottom: 16px;
  &:last-child {
    margin-bottom: 0;
  }
}
`
const StyledComponent = styled(Component)`
  background-color: ${({ theme }) => theme.colors.primary};
  ${media.lessThan("medium")`${spStyle}`}
  ${media.greaterThan("medium")`${pcStyle}`}
`
// ______________________________________________________
//
export default StyledComponent
