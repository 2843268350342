import React from "react"
import styled from "styled-components"
import media from "styled-media-query"
import Title from "@/src/components/common/title/h3"
import { PagesTopQuery } from "@/types"
// ______________________________________________________
//
export type Sponsor = PagesTopQuery["allContentfulSponsor"]["edges"][0]
export type Props = {
  className?: string
  title: string
  plan: "special" | "platinum" | "gold" | "silver"
  sponsors: Sponsor[]
}
// ______________________________________________________
//
const Component: React.FC<Props> = props => (
  <section className={props.className}>
    <Title className="title" figurePosition="right">
      {props.title}
    </Title>
    <ul>
      {props.sponsors.map((sponsor, index) => (
        <li key={sponsor.node.name || index}>
          <a
            href={sponsor.node.url || ""}
            target="_blank"
            rel="noopener noreferrer"
            aria-label={sponsor.node.name || ""}
            style={{
              backgroundImage: `url(${sponsor.node.logo?.file?.url})`,
              backgroundSize: "contain",
            }}
          />
        </li>
      ))}
    </ul>
  </section>
)
// ______________________________________________________
//
const ListItemStylePC = {
  special: `
    margin: 0 auto;
    a {
      width: 470px;
      height: 280px;
    }
  `,
  platinum: `
    margin: 0 30px 26px 0;
    &:last-child,
    &:nth-child(2n) {
      margin-right: 0;
    }
    a {
      width: 470px;
      height: 280px;
    }
  `,
  gold: `
    margin: 0 30px 26px 0;
    &:last-child,
    &:nth-child(4n) {
      margin-right: 0;
    }
    a {
      width: 220px;
      height: 131px;
    }
  `,
  silver: `
    margin: 0 30px 26px 0;
    &:last-child,
    &:nth-child(6n) {
      margin-right: 0;
    }
    a {
      width: 136px;
      height: 80px;
    }
  `,
}
const ListItemStyleSP = {
  special: `
    width: 100%;
    margin-bottom: 20px;
    a {
      width: 100%;
      padding-top: 59.57%;
    }
  `,
  platinum: `
    width: 100%;
    margin-bottom: 20px;
    a {
      width: 100%;
      padding-top: 59.57%;
    }
  `,
  gold: `
    width: 47%;
    margin-bottom: 6%;
    &:last-child,
    &:nth-child(2n) {
      margin-left: 6%;
    }
    a {
      width: 100%;
      padding-top: 59.57%;
    }
  `,
  silver: `
    width: 30%;
    margin: 0 5% 5% 0;
    &:last-child,
    &:nth-child(3n) {
      margin-right:0;
    }
    a {
      width: 100%;
      padding-top: 59.57%;
    }
  `,
}
const StyledComponent = styled(Component)`
  > .title {
    margin-bottom: 20px;
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  li {
    ${media.lessThan<Props>("medium")`
      ${props => ListItemStyleSP[props.plan]}
    `}
    ${media.greaterThan<Props>("medium")`
      ${props => ListItemStylePC[props.plan]}
    `}
    a {
      display: block;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      position: relative;
      transition: transform ease-out 0.1s;

      &:hover {
        transform: scale(1.06) translateZ(0);
      }
    }
  }
`
// ______________________________________________________
//
const Container: React.FC<Props> = props => {
  if (props.sponsors.length === 0) return null
  return <StyledComponent {...props} />
}
// ______________________________________________________
//
export default Container
