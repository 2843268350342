import React from "react"
import styled from "styled-components"
import media from "styled-media-query"
import Title from "@/src/components/common/title/h2"
import Photos from "./photos"
import { PagesTopQuery } from "@/types"
// ______________________________________________________
//
export type Paragraph = PagesTopQuery["allContentfulParagraph"]["edges"][0]
type Props = {
  className?: string
}
// ______________________________________________________
//
const Component: React.FC<Props> = props => (
  <div className={props.className}>
    <Photos className="photos" />
    <Title className="title" figurePosition="right">
      ABOUT
    </Title>
  </div>
)
// ______________________________________________________
//
const pcStyle = `
position: relative;
> .photos {
  left: -40px;
  position: relative;
}
> .photos {
  top: 90px;
  left: -80px;
  position: absolute;
}
> .title {
  position: relative;
}
`
const spStyle = `
padding-top: 40px;
position: relative;
> .photos {
  width: 360px;
  height: 205px;
  top: -150px;
  left: -80px;
  position: absolute;
}
> .title {
  position: relative;
}
`
const StyledComponent = styled(Component)`
  ${media.lessThan("medium")`${spStyle}`}
  ${media.greaterThan("medium")`${pcStyle}`}
`
// ______________________________________________________
//
export default StyledComponent
