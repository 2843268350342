import React from "react"
import styled from "styled-components"
import media from "styled-media-query"
import Unit from "./unit"
// ______________________________________________________
//
type Props = {
  className?: string
}
// ______________________________________________________
//
const Component: React.FC<Props> = props => (
  <div className={props.className}>
    <Unit radius={8} ratios={[2, 4, 2]} />
    <Unit radius={12} ratios={[3, 5, 2]} />
    <Unit radius={12} ratios={[4, 4, 1]} />
    <Unit radius={8} ratios={[2, 4, 5]} />
    <Unit radius={8} ratios={[3, 5, 2]} />
    <Unit radius={12} ratios={[3, 6, 5]} />
    <Unit radius={12} ratios={[4, 4, 1]} />
    <Unit radius={8} ratios={[2, 4, 5]} />
  </div>
)
// ______________________________________________________
//
const pcStyle = `
> * {
  width: 30%;
  &:nth-child(1) {
    top: 410px;
    left: -10%;
  }
  &:nth-child(2) {
    top: 430px;
    left: -8%;
  }
  &:nth-child(3) {
    top: 453px;
    left: -4%;
  }
  &:nth-child(4) {
    top: 480px;
    left: -2%;
  }
  &:nth-child(5) {
    top: 160px;
    right: -10%;
  }
  &:nth-child(6) {
    top: 180px;
    right: -8%;
  }
  &:nth-child(7) {
    top: 203px;
    right: -4%;
  }
  &:nth-child(8) {
    top: 230px;
    right: -2%;
  }
}
`
const spStyle = `
transform: scale(.6);
> * {
  width: 100%;
  &:nth-child(1) {
    // top: 410px;
    top: 540px;
    left: -50%;
  }
  &:nth-child(2) {
    // top: 430px;
    top: 560px;
    left: -48%;
  }
  &:nth-child(3) {
    // top: 453px;
    top: 583px;
    left: -44%;
  }
  &:nth-child(4) {
    // top: 480px;
    top: 610px;
    left: -42%;
  }
  &:nth-child(5) {
    top: 0px;
    right: -50%;
  }
  &:nth-child(6) {
    top: 20px;
    right: -48%;
  }
  &:nth-child(7) {
    top: 43px;
    right: -44%;
  }
  &:nth-child(8) {
    top: 70px;
    right: -42%;
  }
}
`
const StyledComponent = styled(Component)`
  > * {
    position: absolute;
  }
  ${media.lessThan("medium")`${spStyle}`}
  ${media.greaterThan("medium")`${pcStyle}`}
`
// ______________________________________________________
//
export default StyledComponent
