import React from "react"
import styled from "styled-components"
import media from "styled-media-query"
import Title from "@/src/components/common/title/h2"
import List from "./list"
import { Speaker } from "./list/item"
// ______________________________________________________
//
type Props = {
  className?: string
  speakers: Speaker[]
}
// ______________________________________________________
//
const Component: React.FC<Props> = props => (
  <section className={props.className} id="speakers">
    <div className="container">
      <Title className="title" figurePosition="right">
        SPEAKERS
      </Title>
      <List speakers={props.speakers} />
    </div>
  </section>
)
// ______________________________________________________
//
const pcStyle = `
position: relative;
&::before {
  content: "";
  display: block;
  width: 60%;
  height: 600px;
  position: absolute;
  left: 0;
}
> .container {
  width: 970px;
  margin: 0 auto;
  padding: 40px;
  position: relative;
  > .title {
    margin-bottom: 60px;
  }
}
`
const spStyle = `
padding: 20px;
> .container {
  > .title {
    margin-bottom: 20px;
  }
}
`
const StyledComponent = styled(Component)`
  ${media.lessThan("medium")`
    background-color: ${({ theme }) => theme.colors.wood};
    ${spStyle}
  `}
  ${media.greaterThan("medium")`
    &::before { background-color: ${({ theme }) => theme.colors.wood}; }
    ${pcStyle}
  `}
`
// ______________________________________________________
//
export default StyledComponent
