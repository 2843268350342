import React from "react"
import styled from "styled-components"
import media from "styled-media-query"
import { PagesTopQuery } from "@/types"
// ______________________________________________________
//
export type Paragraph = PagesTopQuery["allContentfulParagraph"]["edges"][0]
type Props = {
  className?: string
  paragraph?: Paragraph
}
// ______________________________________________________
//
const Component: React.FC<Props> = props => (
  <div
    className={props.className}
    dangerouslySetInnerHTML={{
      __html: props.paragraph?.node?.text?.text || "",
    }}
  />
)
// ______________________________________________________
//
const pcStyle = `
width: 460px;
font-size: 1.8rem;
line-height: 2.0;
font-weight: bold;
`
const spStyle = `
font-size: 1.6rem;
line-height: 1.8;
`
const StyledComponent = styled(Component)`
  font-weight: bold;
  dl {
    font-size: 1.6rem;
    line-height: 1.7;
    padding-top: 30px;
    margin-top: 20px;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
  }

  a {
    color: ${({ theme }) => theme.colors.light};
    &:hover {
      color: ${({ theme }) => theme.colors.sky};
    }
  }
  ${media.lessThan("medium")`${spStyle}`}
  ${media.greaterThan("medium")`${pcStyle}`}
`
// ______________________________________________________
//
export default StyledComponent
