import React from "react"
import styled from "styled-components"
import media from "styled-media-query"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"
// ______________________________________________________
//
type Props = {
  className?: string
}
// ______________________________________________________
//
const Component: React.FC<Props> = props => (
  <div className={props.className}>
    <StaticQuery
      query={query}
      render={data => <Img fixed={data.file.childImageSharp.fixed} />}
    />
  </div>
)
// ______________________________________________________
//
const pcStyle = `
&::before {
  width: 1420px;
  height: 400px;
  top: -46px;
  left: 46px;
}
.gatsby-image-wrapper {
  width: 420px !important;
  height: 398px !important;
}
`
const spStyle = `
width: 210px;
margin: 40px auto;
&::before {
  width: 210px;
  height: 200px;
  top: -20px;
  left: 20px;
}
.gatsby-image-wrapper {
  width: 210px !important;
  height: 199px !important;
}
`
const StyledComponent = styled(Component)`
  position: relative;
  &::before {
    content: "";
    display: block;
    position: absolute;
    background-color: ${({ theme }) => theme.colors.grape};
  }
  img {
    position: relative;
  }
  ${media.lessThan("medium")`${spStyle}`}
  ${media.greaterThan("medium")`${pcStyle}`}
`
// ______________________________________________________
//
export default StyledComponent
// ______________________________________________________
//
const query = graphql`
  query {
    file(relativePath: { eq: "navitime.jpg" }) {
      childImageSharp {
        fixed(width: 420, quality: 80) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
