import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import media from "styled-media-query"
import { PagesTopQuery } from "@/types"
import SpeakerSNS from "@components/common/speakerSns"
// ______________________________________________________
//
export type Speaker = PagesTopQuery["allContentfulSpeaker"]["edges"][0]
export type Props = {
  className?: string
} & Speaker
// ______________________________________________________
//
const Component: React.FC<Props> = props => (
  <div className={props.className}>
    <Link
      to={`/2020/sessions/${props.node.session && props.node.session[0]?.slug}`}
    >
      <div className="avatar">
        <img
          // @ts-ignore
          loading="lazy"
          src={`${props.node.avatar?.file?.url ?? ""}?w=220&h=220`}
        />
      </div>
      <h3 className="name">{props.node.name}</h3>
    </Link>
    <SpeakerSNS
      className="sns"
      githubAccount={props.node.githubAccount}
      twitterAccount={props.node.twitterAccount}
    />
  </div>
)
// ______________________________________________________
//
const pcStyle = `
a {
  > .avatar {
    width: 220px;
    height: 220px;
    margin-bottom: 10px;
    img {
      max-width: 220px;
      max-height: 220px;
    }
  }
  > .name {
    font-size: 2.4rem;
    line-height: 1.2;
  }
}
`
const spStyle = `
a {
  > .avatar {
    width: 100%;
    margin-bottom: 10px;
  }
  > .name {
    font-size: 2.0rem;
    line-height: 1.2;
  }
}
`
const StyledComponent = styled(Component)`
  a {
    display: block;
    text-decoration: none;
    color: ${({ theme }) => theme.colors.light};
    transition: color ease-out 0.1s;
    > .avatar {
      overflow: hidden;
      > img {
        transition: transform ease-out 0.1s;
        max-width: 100%;
      }
    }
    :hover {
      color: ${({ theme }) => theme.colors.sky};
      img {
        transform: scale(1.06) translateZ(0);
      }
    }
  }
  > .sns {
    margin-top: 10px;
  }
  ${media.lessThan("medium")`${spStyle}`}
  ${media.greaterThan("medium")`${pcStyle}`}
`
// ______________________________________________________
//
export default StyledComponent
