import React from "react"
import styled from "styled-components"
import media from "styled-media-query"
import Icon, { IconName } from "./icon"
import { PagesTopQuery } from "@/types"
// ______________________________________________________
//
export type Program = PagesTopQuery["allContentfulProgram"]["edges"][0]
type Props = {
  className?: string
  program: Program
}
// ______________________________________________________
//
const Component: React.FC<Props> = ({ className, program }) => (
  <li className={className}>
    <p className="time">
      {program.node.startsAt} - {program.node.endsAt}
    </p>
    <Icon className="icon" iconName={program.node.iconName as IconName} />
    <div className="outline">
      <p className="programName">{program.node.name}</p>
    </div>
  </li>
)
// ______________________________________________________
//
const pcStyle = `
padding: 26px 32px;
&::before {
  left: 254px;
}
> .icon {
  margin: 0 32px;
}
`
const spStyle = `
padding: 48px 20px 26px;
&::before {
  left: 54px;
}
> .time {
  top: 12px;
  left: 20px;
  position: absolute;
  font-size: 1.8rem;
}
> .outline {
  margin-left: 20px;
}
`
const StyledComponent = styled(Component)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  &::before {
    content: "";
    display: block;
    width: 4px;
    height: 100%;
    top: 0;
    position: absolute;
    background-color: ${({ theme }) => theme.colors.ts};
    opacity: 0.4;
  }
  &:first-child {
    &::before {
      top: 50%;
      height: 50%;
    }
  }
  &:last-child {
    &::before {
      height: 50%;
    }
  }
  > .time {
    font-size: 2.4rem;
    font-family: "Roboto", sans-serif;
  }
  > .icon {
    position: relative;
  }
  > .outline {
    > .programName {
      font-size: 1.8rem;
      font-weight: bold;
    }
  }
  ${media.lessThan("medium")`${spStyle}`}
  ${media.greaterThan("medium")`${pcStyle}`}
`
// ______________________________________________________
//
export default StyledComponent
