import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import media from "styled-media-query"
import { PagesTopQuery } from "@/types"
// ______________________________________________________
//
export type Session = PagesTopQuery["allContentfulSession"]["edges"][0]
type ContainerProps = {
  className?: string
  session: Session
}
type Props = {
  className?: string
  linkTo: string
  timeLabel: string
  imageTags: React.ReactNode[]
  sessionName: string
  speakerName: string
}
// ______________________________________________________
//
const Component: React.FC<Props> = props => (
  <li className={props.className}>
    <Link to={props.linkTo}>
      <p className="time">{props.timeLabel}</p>
      {props.imageTags.map(Tag => Tag)}
      <div className="outline">
        <p className="sessionName">{props.sessionName}</p>
        <p className="speakerName">{props.speakerName}</p>
      </div>
    </Link>
  </li>
)
// ______________________________________________________
//
const pcStyle = `
&::before {
  left: 254px;
}
a {
  padding: 26px 32px;
  > .avatar {
    width: 96px;
    height: 96px;
    border-radius: 96px;
    margin: 0 32px;
  }
}
`
const spStyle = `
&::before {
  left: 54px;
}
a {
  padding: 48px 20px 26px;
  > .time {
    top: 12px;
    left: 20px;
    position: absolute;
    font-size: 1.8rem;
  }
  > .avatar {
    width: 72px;
    min-width: 72px;
    height: 72px;
    border-radius: 72px;
  }
  > .outline {
    margin-left: 20px;
  }
}
`
const StyledComponent = styled(Component)`
  position: relative;
  &::before {
    content: "";
    display: block;
    width: 4px;
    height: 100%;
    top: 0;
    position: absolute;
    background-color: ${({ theme }) => theme.colors.ts};
    opacity: 0.4;
  }
  &:first-child {
    &::before {
      top: 50%;
      height: 50%;
    }
  }
  &:last-child {
    &::before {
      height: 50%;
    }
  }
  a {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-decoration: none;
    color: ${({ theme }) => theme.colors.light};
    transition: color ease-in 0.1s;

    &:hover {
      color: ${({ theme }) => theme.colors.sky};
      .speakerName {
        color: ${({ theme }) => theme.colors.sky};
      }

      > .avatar {
        opacity: 0.8;
      }
    }
    > .time {
      font-size: 2.4rem;
      font-family: "Roboto", sans-serif;
    }
    > .avatar {
      display: block;
      position: relative;
      transition: opacity ease-in 0.1s;
    }
    > .outline {
      flex: 1;
    }
    .sessionName {
      font-size: 1.8rem;
      font-weight: bold;
    }
    .speakerName {
      margin-top: 1em;
      font-size: 1.6rem;
      color: #c4c4c4;
      transition-duration: 0.1s;
      transition-property: color;
    }
  }
  ${media.lessThan("medium")`${spStyle}`}
  ${media.greaterThan("medium")`${pcStyle}`}
`
// ______________________________________________________
//
const Container: React.FC<ContainerProps> = props => {
  if (!props.session.node.speakers) return <></>
  const linkTo = `/2020/sessions/${props.session.node.slug}`
  const timeLabel = `${props.session.node.startsAt} - ${props.session.node.endsAt}`
  const imageTags = props.session.node.speakers.map((speaker, i) => (
    <img
      key={i}
      className="avatar"
      // @ts-ignore
      loading="lazy"
      src={speaker?.avatar?.file?.url ?? ""}
    />
  ))
  const sessionName = props.session.node.name ?? ""
  const speakerName =
    props.session.node.speakers.length === 1
      ? props.session.node.speakers[0]?.name ?? ""
      : props.session.node.speakers.length > 1
      ? props.session.node.speakers
          .map(speaker => speaker?.name ?? "")
          .reduce((a, b) => `${a} & ${b}`)
      : ""
  return (
    <StyledComponent
      className={props.className}
      linkTo={linkTo}
      timeLabel={timeLabel}
      imageTags={imageTags}
      sessionName={sessionName}
      speakerName={speakerName}
    />
  )
}
// ______________________________________________________
//
export default Container
