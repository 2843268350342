import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import media from "styled-media-query"
import { PagesTopQuery } from "@/types"
import SpeakerSNS from "@components/common/speakerSns"
// ______________________________________________________
//
export type Speaker = PagesTopQuery["allContentfulSpeaker"]["edges"][0]
export type Props = {
  className?: string
} & Speaker
// ______________________________________________________
//
const Component: React.FC<Props> = props => (
  <div className={props.className}>
    <img
      className="avatar"
      // @ts-ignore
      loading="lazy"
      src={`${props.node.avatar?.file?.url ?? ""}?w=220&h=220`}
    />
    <h3 className="name">{props.node.name}</h3>
    <SpeakerSNS
      className="sns"
      githubAccount={props.node.githubAccount}
      twitterAccount={props.node.twitterAccount}
    />
  </div>
)
// ______________________________________________________
//
const pcStyle = `
> .avatar {
  width: 220px;
  height: 220px;
  max-width: 220px;
  max-height: 220px;
  margin-bottom: 10px;
}
> .name {
  font-size: 2.4rem;
  line-height: 1.2;
}
`
const spStyle = `
> .avatar {
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  margin-bottom: 6px;
}
> .name {
  font-size: 2.0rem;
  line-height: 1.2;
}
`
const StyledComponent = styled(Component)`
  color: ${({ theme }) => theme.colors.light};
  > .avatar {
    background-size: cover;
    background-position: center;
  }
  > .sns {
    margin-top: 10px;
  }
  ${media.lessThan("medium")`${spStyle}`}
  ${media.greaterThan("medium")`${pcStyle}`}
`
// ______________________________________________________
//
export default StyledComponent
