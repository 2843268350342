import React from "react"
import styled from "styled-components"
import media from "styled-media-query"
import Title from "@/src/components/common/title/h2"
import List, { Props as ListProps } from "./list"
// ______________________________________________________
//
type Props = {
  className?: string
  lists: ListProps[]
}
// ______________________________________________________
//
const Component: React.FC<Props> = props => (
  <section className={props.className} id="spon">
    <div className="container">
      <Title className="title" figurePosition="bottom">
        SPONSORS
      </Title>
      {props.lists.map(list => (
        <List
          key={list.title}
          className="list"
          title={list.title}
          plan={list.plan}
          sponsors={list.sponsors}
        />
      ))}
    </div>
  </section>
)
// ______________________________________________________
//
const pcStyle = `
> .container {
  width: 970px;
  margin: 0 auto;
  padding: 20px 40px 120px;
  > .list {
    margin-bottom: 10px;
  }
}
`
const spStyle = `
padding: 20px;
> .container {
  > .title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-top: 0;
    &::after {
      content: "";
      display: block;
      width: 75px;
      height: 3px;
      margin-left: 30px;
      background-color: #fff;
    }
  }
  > .list {
    margin-bottom: 20px;
  }
}
`
const StyledComponent = styled(Component)`
  background-color: ${({ theme }) => theme.colors.ts};
  ${media.lessThan("medium")`${spStyle}`}
  ${media.greaterThan("medium")`${pcStyle}`}
`
// ______________________________________________________
//
export default StyledComponent
