import React from "react"
import styled from "styled-components"
import media from "styled-media-query"
import Title from "@/src/components/common/title/h2"
import CoreStaff, { Props as CoreStaffProps } from "./coreStaff"
import Volunteer, { Props as VolunteerProps } from "./volunteer"
// ______________________________________________________
//
type Props = {
  className?: string
  coreStaff: CoreStaffProps
  volunteer: VolunteerProps
}
// ______________________________________________________
//
const Component: React.FC<Props> = props => (
  <section className={props.className}>
    <div className="container">
      <Title className="title" figurePosition="bottom">
        TEAM
      </Title>
      {props.coreStaff.members.length !== 0 && (
        <CoreStaff
          className="list"
          title={props.coreStaff.title}
          members={props.coreStaff.members}
        />
      )}
      {props.volunteer.members.length !== 0 && (
        <Volunteer
          className="list"
          title={props.volunteer.title}
          members={props.volunteer.members}
        />
      )}
    </div>
  </section>
)
// ______________________________________________________
//
const pcStyle = `
.container {
  width: 970px;
  margin: 0 auto;
  padding: 20px 40px 120px;
}
`
const spStyle = `
padding: 20px;
> .container {
  > .title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-top: 0;
    &::after {
      content: "";
      display: block;
      width: 75px;
      height: 3px;
      margin-left: 30px;
      background-color: #fff;
    }
  }
  > .list {
    margin-bottom: 20px;
  }
}
`
const StyledComponent = styled(Component)`
  background-color: ${({ theme }) => theme.colors.dark};
  ${media.lessThan("medium")`${spStyle}`}
  ${media.greaterThan("medium")`${pcStyle}`}
`
// ______________________________________________________
//
export default StyledComponent
