import React from "react"
import styled from "styled-components"
import media from "styled-media-query"
import Title from "@/src/components/common/title/h3"
import { PagesTopQuery } from "@/types"
// ______________________________________________________
//
type Member = PagesTopQuery["allContentfulCoreStaff"]["edges"][0]
export type Props = {
  className?: string
  title: string
  members: Member[]
}
// ______________________________________________________
//
const Component: React.FC<Props> = props => (
  <section className={props.className}>
    <Title className="title" figurePosition="right">
      {props.title}
    </Title>
    <ul>
      {props.members.map(({ node }, index) => (
        <li key={node.name ?? index}>
          <img
            className="img"
            // @ts-ignore
            loading="lazy"
            src={node.avatar?.file?.url ?? ""}
          />
          {node.name}
        </li>
      ))}
    </ul>
  </section>
)
// ______________________________________________________
//
const pcStyle = `
li {
  width: 96px;
  margin: 0 28.8px 27px 0;
  .img {
    width: 96px;
    height: 96px;
  }
  &:nth-child(8n) {
    margin-right: 0;
  }
}
`
const spStyle = `
li {
  width: 30%;
  margin: 0 5% 5% 0;
  .img {
    width: 100%;
  }
  &:nth-child(3n) {
    margin-right:0;
  }
}
`
const StyledComponent = styled(Component)`
  > .title {
    margin-bottom: 20px;
  }
  ul {
    display: flex;
    flex-wrap: wrap;
  }
  li {
    font-family: "Roboto", sans-serif;
    font-size: 1.4rem;
    line-height: 1.2;

    .img {
      margin-bottom: 6px;
      background-position: center;
      background-size: cover;
    }
  }
  ${media.lessThan("medium")`${spStyle}`}
  ${media.greaterThan("medium")`${pcStyle}`}
`
// ______________________________________________________
//
export default StyledComponent
