import React from "react"
import styled from "styled-components"
import media from "styled-media-query"
import Face from "./face.svg"
import Galss from "./glass.svg"
import Microphone from "./microphone.svg"
import Play from "./play.svg"
import Coffee from "./coffee.svg"
import Tableware from "./tableware.svg"
// ______________________________________________________
//
export type IconName =
  | "face"
  | "glass"
  | "microphone"
  | "play"
  | "tableware"
  | "coffee"
type Props = {
  className?: string
  iconName: IconName
}
// ______________________________________________________
//
const Component: React.FC<Props> = props => {
  switch (props.iconName) {
    case "face":
      return (
        <p className={props.className}>
          <Face />
        </p>
      )
    case "glass":
      return (
        <p className={props.className}>
          <Galss />
        </p>
      )
    case "microphone":
      return (
        <p className={props.className}>
          <Microphone />
        </p>
      )
    case "play":
      return (
        <p className={props.className}>
          <Play />
        </p>
      )
    case "tableware":
      return (
        <p className={props.className}>
          <Tableware />
        </p>
      )
    case "coffee":
      return (
        <p className={props.className}>
          <Coffee />
        </p>
      )
  }
}
// ______________________________________________________
//
const pcStyle = `
width: 96px;
min-width: 96px;
height: 96px;
border-radius: 96px;
`
const spStyle = `
width: 72px;
min-width: 72px;
height: 72px;
border-radius: 72px;
> * {
  transform: scale(.7);
}
`
const StyledComponent = styled(Component)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.ts};

  ${media.lessThan("medium")`${spStyle}`}
  ${media.greaterThan("medium")`${pcStyle}`}
`
// ______________________________________________________
//
export default StyledComponent
