import React from "react"
import styled from "styled-components"
// ______________________________________________________
//
type Props = {
  className?: string
}
// ______________________________________________________
//
const Component: React.FC<Props> = props => (
  <div className={props.className}>
    <span />
    <span />
    <span />
  </div>
)
// ______________________________________________________
//
const StyledComponent = styled(Component)`
  width: 230px;
  height: 230px;
  border-radius: 230px;
  background-color: rgba(31, 28, 180, 0.29);
  span {
    display: block;
    position: absolute;
    &:nth-child(1) {
      width: 120px;
      height: 120px;
      border-radius: 120px;
      top: 72px;
      left: 72px;
      background-color: #1c6bb4;
    }
    &:nth-child(2) {
      width: 38px;
      height: 38px;
      border-radius: 38px;
      top: 182px;
      left: 182px;
      background-color: rgba(31, 28, 180, 0.59);
    }
    &:nth-child(3) {
      width: 24px;
      height: 24px;
      border-radius: 24px;
      top: 228px;
      left: 228px;
      background-color: rgba(31, 28, 180, 0.59);
    }
  }
`
// ______________________________________________________
//
export default StyledComponent
